<template>
  <section id="faq-page">
    <vs-collapse accordion type="margin" class="p-0">
      <vs-collapse-item
        v-for="(que, index) in faqs"
        class="faq-bg rounded-lg"
        :key="index"
      >
        <div slot="header">
          <h6>{{ que.question }}</h6>
        </div>
        <p>{{ que.ans }}</p>
      </vs-collapse-item>
    </vs-collapse>

    <div class="tip">
      <vs-chip color="danger">
        tip
      </vs-chip>
      <p class="text-danger">
        The freezing period must be at least one week in order to earn up that
        week's dividend, otherwise you will only receive the weekly stock after
        the weekly free.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      faqs: "faq/faqs"
    })
  }
};
</script>
