<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full d-theme-dark-bg">
              <div class="p-8 faq-tabs-container">
                <div class="vx-card__title mb-4">
                  <div class="flex">
                    <a v-back class="pointer">
                      <feather-icon
                        icon="ArrowLeftIcon"
                        class="pr-3"
                      ></feather-icon>
                    </a>

                    <h4 class="mb-4">FAQs</h4>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full">
                    <FAQ />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import FAQ from "@/components/faq/FAQ.vue";

export default {
  components: { FAQ }
};
</script>

<style lang="scss">
@import "./faq.scss";
</style>
